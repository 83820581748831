import React from "react";
import styled, { keyframes } from "styled-components";
import holylights from "../images/holylights.jpeg";
import creampuff from "../images/creampuf.jpeg";
import holysound from "../audio/holysound-compressed.mp3";
import Frame from "react-frame-component";
import "@fontsource/unifrakturmaguntia";

const scaleAnimation = keyframes`
  0% {transform: scale(1); }
  100% {transform: scale(.5); }
`;

const fadeInPuff = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1); 
  }
`;

const pulse = keyframes`
  0% {transform: scale(1);}
  50% {transform: scale(1.1);}
  100% {transform: scale(1);}
`;

const bgAnimation = keyframes`
  0% {opacity: .2; }
  100% {opacity: 1; }
`;

const HolyLight = styled.div`
  height: 100vh;
  max-width: 100%;
  background: url(${holylights});
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  animation ${bgAnimation} 1.5s ease-in;
`;

const CreampuffImage = styled.img`
  position: absolute;
  top: 30%;
  left: 50%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  height: 440px;
  width: 540px;
  animation-name: ${fadeInPuff};
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.71, 0.55, 0.62, 1.57);
  @media (max-width: 420px) {
    height: 220px;
    width: 280px;
  }
`;

const Background = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  text-align: center;
  height: 100vh;
  width: 100vw;
  background: #000;
  font-family: "UnifrakturMaguntia", cursive;
  h1 {
    color: #fff;
    font-size: 44px;
    animation: ${pulse} 1s infinite ease-in;
    @media (max-width: 720px) {
      font-size: 24px;
    }
  }
`;

export default function App() {
  const ref = React.useRef(null);
  const [entered, setEntered] = React.useState(false);
  const [shown, setShown] = React.useState(false);
  const [time, setTime] = React.useState(0);

  React.useEffect(() => {
    const handleKeyDown = (ev) => {
      if (ev.key === "Enter") setEntered(true);
      if (ev.touches && ev.touches.length > 0) setEntered(true);
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("touchstart", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("touchstart", handleKeyDown);
    };
  }, [setEntered]);

  React.useLayoutEffect(() => {
    let timeout;
    if (ref && ref.current && entered) {
      ref.current.muted = false;
      ref.current.play();
      timeout = setTimeout(() => setShown(true), 800);
    }
    return () => clearTimeout(timeout);
  }, [entered, setTime, setShown]);

  return (
    <>
      {!entered ? (
        <Background>
          <h1>Press Enter to enter the church</h1>
        </Background>
      ) : (
        <>
          <HolyLight />
          {shown && <CreampuffImage src={creampuff} />}
          <audio muted loop src={holysound} ref={ref} type="audio/mp3" />
        </>
      )}
    </>
  );
}
